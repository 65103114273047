import template1 from "../../assets/template_1.png";
import template2 from "../../assets/template_2.png";
import template3 from "../../assets/template_3.png";
import template4 from "../../assets/template_4.png";
import template5 from "../../assets/template_5.png";
import dotsBlue from "../../assets/dots_blue.svg";
import dotsPink from "../../assets/dots_pink.svg";
import dotsPurple from "../../assets/dots_purple.svg";
import strokeBg1 from "../../assets/stroke_background_1.svg";
import strokeBg2 from "../../assets/stroke_background_2.svg";
import strokeBg3 from "../../assets/stroke_background_3.svg";
import strokeBg4 from "../../assets/stroke_background_4.svg";
import strokeBg5 from "../../assets/stroke_background_5.svg";

export const cardTemplates = [
  {
    dotsImage: dotsPurple,
    gradientColorTop: "#B6EBF8",
    gradientColorBottom: "#B7E0F7",
    strokeBgImage: strokeBg1,
    dollBackgroundImage: template1,
  },
  {
    dotsImage: dotsBlue,
    gradientColorTop: "#C8C7F8",
    gradientColorBottom: "#C9C6FE",
    strokeBgImage: strokeBg2,
    dollBackgroundImage: template2,
  },
  {
    dotsImage: dotsBlue,
    gradientColorTop: "#BFF8DA",
    gradientColorBottom: "#C2FAAD",
    strokeBgImage: strokeBg3,
    dollBackgroundImage: template3,
  },
  {
    dotsImage: dotsPink,
    gradientColorTop: "#F8D0ED",
    gradientColorBottom: "#FFBFE8",
    strokeBgImage: strokeBg4,
    dollBackgroundImage: template4,
  },
  {
    dotsImage: dotsPink,
    gradientColorTop: "#DFCBEB",
    gradientColorBottom: "#F1A8FF",
    strokeBgImage: strokeBg5,
    dollBackgroundImage: template5,
  },
];
