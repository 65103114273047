import axios from "axios";
import {
  AIRTABLE_API_KEY,
  AIRTABLE_BASE_ID,
  AIRTABLE_TABLE_NAME,
} from "./airtableKeys";

export const fetchQuestions = async () => {
  try {
    const response = await axios.get(
      `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}`,
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
        },
      }
    );
    const fetchedQuestions = response.data.records.map((record) => {
      const {
        category,
        question,
        answer1,
        answer2,
        answer3,
        trait1,
        trait2,
        trait3,
      } = record.fields;
      return {
        id: record.id,
        category,
        question,
        answerOptions: [
          {
            answer: answer1,
            trait: trait1,
          },
          {
            answer: answer2,
            trait: trait2,
          },
          {
            answer: answer3,
            trait: trait3,
          },
        ],
      };
    });

    const categorizedQuestions = fetchedQuestions.reduce((acc, question) => {
      const category = question.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(question);
      return acc;
    }, {});

    return categorizedQuestions;
  } catch (error) {
    console.error("Error fetching questions:", error);
    return null;
  }
};
