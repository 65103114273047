import Pannel from "../Pannel";
import { useState } from "react";
import "./index.css";

const CollectNamePannel = ({ handleCollectName }) => {
  const [userName, setUserName] = useState("");
  return (
    <Pannel>
      <div className="name_input_wrap">
        <h2>What's your name?</h2>
        <form
          className="name_input_form"
          onSubmit={() => handleCollectName(userName)}
        >
          <input
            type="text"
            value={userName}
            className="username_input"
            name="firstName"
            placeholder="Your name"
            autoComplete="given-name"
            onChange={(e) => setUserName(e.target.value)}
            required
          />
          <button
            disabled={userName.length === 0}
            className="purple_button quiz_collectname"
            type="submit"
          >
            Next
          </button>
        </form>
      </div>
    </Pannel>
  );
};

export default CollectNamePannel;
