import { useGate } from "statsig-react";
import Pannel from "../Pannel";
import "./index.css";
import { FeatureGate } from "../../../statsig";

const PRE_ORDER_LINK =
  "https://apps.apple.com/us/app/my-genie-personality-ai/id6468962977";
const BETA_LINK = "https://apple.co/3Hdxt5d";

const PreorderPannel = ({ handleNext }) => {
  const isBetaLinkRedirect = useGate(FeatureGate.BETA_LINK_REDIRECT)?.value;

  const onClickRedirectButton = () => {
    window.open(isBetaLinkRedirect ? BETA_LINK : PRE_ORDER_LINK, "_blank");
    setTimeout(() => {
      handleNext();
    }, 2500);
  };

  return (
    <Pannel>
      <div className="preorder_screen">
        <h2>
          {isBetaLinkRedirect
            ? "Be one of the first to try our Personality AI app (results shown after)"
            : "Pre-order the app to see your results!"}
        </h2>
        <button
          type="submit"
          className={`purple_button preorder_button ${
            isBetaLinkRedirect ? "quiz_betalink" : "quiz_preorder"
          }`}
          onClick={onClickRedirectButton}
        >
          {isBetaLinkRedirect ? "Try it for FREE" : "Pre-order for FREE"}
        </button>
      </div>
    </Pannel>
  );
};

export default PreorderPannel;
