import { useRef, useState } from "react";
import Pannel from "../Pannel";
import "./index.css";

const QuestionPannel = ({
  questionAnswers,
  screenIndex,
  handleNext,
  handleAnswer,
}) => {
  const [selected, setSelected] = useState(-1);
  const resultAnswer = useRef("");
  const resultTrait = useRef("");

  const onClickOption = (answer, trait, index) => {
    resultAnswer.current = answer;
    resultTrait.current = trait;
    setSelected(index);
  };

  return (
    <Pannel>
      <div className="question_pannel_wrap">
        <h2>{questionAnswers.question}</h2>
        <div className="answers_wrap">
          {questionAnswers.answerOptions.map(({ answer, trait }, index) => (
            <button
              key={answer}
              className={`black_button ${
                selected === index ? "black_button_selected" : ""
              } option`}
              onClick={() => onClickOption(answer, trait, index)}
            >
              {answer}
            </button>
          ))}
        </div>
        <button
          className="purple_button next_button quiz_screen"
          disabled={selected === -1}
          data-quiz-screen={screenIndex}
          onClick={() => {
            handleAnswer(
              questionAnswers.question,
              resultAnswer.current,
              resultTrait.current
            );
            handleNext();
          }}
        >
          Next
        </button>
      </div>
    </Pannel>
  );
};

export default QuestionPannel;
