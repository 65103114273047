import React, { useEffect, useRef, useState } from "react";
import "@lottiefiles/lottie-player";
import { fetchQuestions } from "./edge/fetchQuestions";
import { fetchTraitDetails } from "./edge/fetchTraitDetails";
import Layout from "./components/Layout";
import LoaderSpinner from "./components/LoaderSpinner";
import {
  WelcomePannel,
  CollectNamePannel,
  QuestionPannel,
  PreorderPannel,
} from "./components/Pannels";
import "./App.css";
import { TraitsCardDesktop, TraitsCardMobile } from "./components/TraitsCard";
import { cardTemplates } from "./components/TraitsCard/cardTemplates";

const categoryOrder = [
  "music",
  "friendship",
  "entertainment",
  "food",
  "goals",
  "socialmedia",
  "relationships",
  "personalstyle",
  "habitsandbehaviors",
  "emotions",
];

const getRandomCardTemplate = () => {
  return cardTemplates[Math.floor(Math.random() * (cardTemplates.length - 1))];
};

// screen index
const WELCOME_SCREEN_INDEX = 0;
const COLLECT_NAME_SCREEN = 11;
const PRE_ORDER_SCREEN = 12;
const TRAITS_CARD_SCREEN = 13;

const calculateResult = (e) => {
  return Object.keys(e).reduce((a, b) => (e[a] > e[b] ? a : b));
};

const getRandomQuestionFromCategory = (categorizedQuestions, category) => {
  const questionsInCategory = categorizedQuestions[category];
  return questionsInCategory[
    Math.floor(Math.random() * questionsInCategory.length)
  ];
};

const Questionnaire = () => {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [screenIndex, setScreenIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const userName = useRef("");
  const traitDetails = useRef(null);
  const traitScores = useRef({});
  const finalResponses = useRef([]);
  const categorizedQuestions = useRef(null);
  const template = getRandomCardTemplate();
  const isMobileDevice = window.innerWidth <= 767;

  useEffect(() => {
    const setupQuestions = async () => {
      setLoading(true);
      const data = await fetchQuestions();
      categorizedQuestions.current = data;
      setLoading(false);
    };
    setupQuestions();
  }, []);

  const handleCollectName = async (nameInput) => {
    setLoading(true);
    userName.current = nameInput;
    setLoadingText("Calculating...");
    const dominantTrait = calculateResult(traitScores.current);
    traitDetails.current = await fetchTraitDetails(dominantTrait);
    setLoading(false);
    setScreenIndex(screenIndex + 1);
  };

  const handleAnswer = (question, answer, trait) => {
    traitScores.current = {
      ...traitScores.current,
      [trait]: (traitScores.current[trait] || 0) + 1,
    };
    finalResponses.current = [...finalResponses.current, question, answer];
  };

  const onClickNext = () => {
    if (screenIndex >= 0 && screenIndex < categoryOrder.length) {
      const nextCategory = categoryOrder[screenIndex];
      setCurrentQuestion(
        getRandomQuestionFromCategory(
          categorizedQuestions.current,
          nextCategory
        )
      );
    }
    setScreenIndex(screenIndex + 1);
  };

  const ScreenComponent = ({ index }) => {
    if (index === WELCOME_SCREEN_INDEX) {
      return <WelcomePannel handlePlay={onClickNext} />;
    }
    if (index > WELCOME_SCREEN_INDEX && index < COLLECT_NAME_SCREEN) {
      return (
        <QuestionPannel
          questionAnswers={currentQuestion}
          screenIndex={index}
          handleNext={onClickNext}
          handleAnswer={handleAnswer}
        />
      );
    }
    if (index === COLLECT_NAME_SCREEN) {
      return <CollectNamePannel handleCollectName={handleCollectName} />;
    }
    if (index === PRE_ORDER_SCREEN) {
      return <PreorderPannel handleNext={onClickNext} />;
    }
    if (index === TRAITS_CARD_SCREEN) {
      return (
        <TraitsCardDesktop
          template={template}
          userName={userName.current}
          traitDetails={traitDetails.current}
        />
      );
    }
  };

  if (isMobileDevice && screenIndex === TRAITS_CARD_SCREEN) {
    return (
      <TraitsCardMobile
        template={template}
        userName={userName.current}
        traitDetails={traitDetails.current}
      />
    );
  }

  return (
    <Layout
      customBackground={
        screenIndex === TRAITS_CARD_SCREEN
          ? {
              background: `linear-gradient(180deg, ${template.gradientColorTop} 0%, #FFF 51.5%, ${template.gradientColorBottom} 100%)`,
            }
          : null
      }
    >
      {loading ? (
        <LoaderSpinner text={loadingText} />
      ) : (
        <ScreenComponent index={screenIndex} />
      )}
    </Layout>
  );
};

export default Questionnaire;
