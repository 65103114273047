import html2canvas from "html2canvas";
import { Fragment, useRef } from "react";
import "./index.css";
import { useWindowSize } from "../../hooks/useWindowSize";

const downloadImage = (elementRef, fileName) => {
  if (!elementRef.current) return;

  html2canvas(elementRef.current, {
    scale: window.devicePixelRatio * 2,
  }).then((canvas) => {
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = fileName;
      link.href = url;
      link.click();
    });
  });
};

const TraitDetails = ({ template, traitDetails }) => {
  return (
    <div className="traitdetails">
      <span className="rebrand_text">your rebrand is...</span>
      <div className="public_trait_area">
        <img className="stroke_backgroud" alt="" src={template.strokeBgImage} />
        <span className="public_trait_text">{traitDetails.publictrait}</span>
      </div>
      <div className="doll_bubbles_area">
        <img src={template.dollBackgroundImage} alt=""></img>
        <div className="superlative_bubble1">{traitDetails.superlative1}</div>
        <div className="superlative_bubble2">{traitDetails.superlative2}</div>
        <div className="superlative_bubble3">{traitDetails.superlative3}</div>
      </div>
    </div>
  );
};

export const TraitsCardDesktop = ({ userName, traitDetails, template }) => {
  const cardRef = useRef(null);

  return (
    <div className="result_card_wrap">
      <div className="trait_card_border">
        <div className="trait_card" ref={cardRef}>
          <div
            className="gradient"
            style={{
              background: `linear-gradient(180deg, ${template.gradientColorTop} 0%, #E7E5E6 46.92%, ${template.gradientColorBottom} 100%)`,
            }}
          />
          <img className="dots_texture" alt="" src={template.dotsImage} />
          <TraitDetails template={template} traitDetails={traitDetails} />
        </div>
      </div>
      <button
        className="save_button quiz_save"
        onClick={() => downloadImage(cardRef, `${userName}-card.jpeg`)}
      >
        Save and Share!
      </button>
    </div>
  );
};

export const TraitsCardMobile = ({ userName, traitDetails, template }) => {
  const [, height] = useWindowSize();
  const cardRef = useRef(null);

  return (
    <Fragment>
      <div
        ref={cardRef}
        className="traits_card_mobile_wrap"
        style={{ height: height }}
      >
        <div
          className="gradient"
          style={{
            background: `linear-gradient(180deg, ${template.gradientColorTop} 0%, #E7E5E6 46.92%, ${template.gradientColorBottom} 100%)`,
          }}
        />
        <img
          className="dots_texture"
          style={{ width: "100%" }}
          alt=""
          src={template.dotsImage}
        />
        <TraitDetails template={template} traitDetails={traitDetails} />
      </div>
      <button
        className="save_button quiz_save"
        onClick={() => downloadImage(cardRef, `${userName}-card.jpeg`)}
      >
        Save and Share!
      </button>
    </Fragment>
  );
};
