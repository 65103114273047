import spinner from "../../assets/spinner.png";
import "./index.css";

const LoaderSpinner = ({ text }) => {
  return (
    <div className="spinner">
      <img src={spinner} alt="Loading..." className="spinner_img" />
      {text && <span> {text}</span>}
    </div>
  );
};

export default LoaderSpinner;
