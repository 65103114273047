import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { StatsigProvider } from "statsig-react";

ReactDOM.render(
  <React.StrictMode>
    <StatsigProvider
      sdkKey={process.env.REACT_APP_STATSIG_CLIENT_SDK_KEY}
      waitForInitialization={true}
      options={{
        environment: {
          tier: process.env.REACT_APP_STATSIG_ENVIRONMENT_TIER,
        },
      }}
    >
      <App />
    </StatsigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals(sendToVercelAnalytics);
