import Pannel from "../Pannel";
import "./index.css";

const WelcomePannel = ({ handlePlay }) => {
  return (
    <Pannel>
      <div className="welcome_screen">
        <h1>Free personality rebrand quiz</h1>
        <button
          className="purple_button start_playing quiz_welcome_play"
          onClick={handlePlay}
        >
          Start Playing
        </button>
      </div>
    </Pannel>
  );
};

export default WelcomePannel;
