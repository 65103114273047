import { useWindowSize } from "../../hooks/useWindowSize";
import geniesLogo from "../../assets/genies_logo.svg";
import backgroundVideo from "../../assets/background.mp4";
import backgroundPosterImg from "../../assets/background_poster.png";

import "./index.css";

const Layout = ({
  hideHeader,
  hideFooter,
  customBackground = null,
  children,
}) => {
  const [, height] = useWindowSize();
  return (
    <div className="container" style={{ minHeight: height }}>
      {customBackground ? (
        <div className="background_video" style={customBackground} />
      ) : (
        <video
          className="background_video"
          poster={backgroundPosterImg}
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      )}
      {/* header */}
      {!hideHeader && (
        <div className="header">
          <img src={geniesLogo} alt="Genies Logo" />
          <span>quiz.genies.com</span>
        </div>
      )}
      <div className="content">{children}</div>
      {/* footer */}
      {!hideFooter && (
        <div className="footer">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://vault.pactsafe.io/s/c6039daa-76fb-4b71-bb07-77676913b3fc/legal.html#contract-rm6xfx7wfj"
          >
            Terms of Service
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://vault.pactsafe.io/s/c6039daa-76fb-4b71-bb07-77676913b3fc/legal.html#contract-jktkfxmfc"
          >
            Privacy Policy
          </a>
        </div>
      )}
    </div>
  );
};

export default Layout;
