import axios from "axios";
import {
  AIRTABLE_API_KEY,
  AIRTABLE_BASE_ID,
  AIRTABLE_TABLE_NAME_TRAITS,
} from "./airtableKeys";

export const fetchTraitDetails = async (dominantTrait) => {
  try {
    const response = await axios.get(
      `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME_TRAITS}?filterByFormula={trait}='${dominantTrait}'`,
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
        },
      }
    );
    if (response.data.records.length > 0) {
      return response.data.records[0].fields;
    }
    return null;
  } catch (error) {
    console.error("Error fetching trait details:", error);
    return null;
  }
};
